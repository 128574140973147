import React from 'react';
import HowToPlayImage from '../assets/howtoplay.png'

const HowToPlay = () => {
    return(
        <div id="documentation">
            <img src={HowToPlayImage} alt="How to play" />
        </div>
    )
}

export default HowToPlay